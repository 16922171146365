import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import '../App.css';

function CustomNavbar({ setActiveSection }) {
  const [expanded, setExpanded] = useState(false);
  const [activeLink, setActiveLink] = useState('Header');

  const handleNavClick = (section) => {
    setActiveSection(section);
    setActiveLink(section);
    setExpanded(false); // Fechar o menu hambúrguer
  };

  return (
    <Navbar expand="lg" className="site-header sticky-top" expanded={expanded}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link
            href="#"
            className={activeLink == 'Header' ? 'ativo' : ''}
            onClick={() => handleNavClick('Header')}
          >
            Início
          </Nav.Link>
          <Nav.Link
            href="#"
            className={activeLink == 'Quem' ? 'ativo' : ''}
            onClick={() => handleNavClick('Quem')}
          >
            Quem sou eu
          </Nav.Link>
          <Nav.Link
            href="#"
            className={activeLink == 'Formacao' ? 'ativo' : ''}
            onClick={() => handleNavClick('Formacao')}
          >
            Formação e Atuação
          </Nav.Link>
          <Nav.Link
            href="#"
            className={activeLink == 'Atendimento' ? 'ativo' : ''}
            onClick={() => handleNavClick('Atendimento')}
          >
            Atendimento
          </Nav.Link>
          <Nav.Link
            href="#"
            className={activeLink == 'Publicacoes' ? 'ativo' : ''}
            onClick={() => handleNavClick('Publicacoes')}
          >
            Publicações
          </Nav.Link>
          <Nav.Link
            href="#"
            className={activeLink == 'Contato' ? 'ativo' : ''}
            onClick={() => handleNavClick('Contato')}
          >
            Contato
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default CustomNavbar;
