import React from 'react';

function Atendimento() {
  return (
    <div>
      <h2 className="m-3">Atendimento</h2>
      <p className="m-5 justificar-texto">Os atendimentos são ofertados de maneira virtual e acompanho pessoas brasileiras adultas pelo mundo. As modalidades de psicoterapia que trabalho são as individual e de relação (casais e outros formatos). A abordagem clínica que baseia meu trabalho é a Sistêmica, na qual sou especialista, mas usufruo dos estudos de Mindfulness, Psicologia Social, Teoria Feminista, Diversidade Sexual e de Gênero, Neoliberalismo, Racismo, Branquitude e Não-Monogamia.</p>
      <p className="m-5 justificar-texto">Mantendo-me comprometida em ofertar uma prática atenta às intersecionalidades e complexidades do viver e um exercício ético e crítico da Psicologia. Possuo experiência no atendimento de mulheres, pessoas LGBTI+ e vivendo com HIV/AIDS. Sendo esses meu foco de atuação, porém não atendo exclusivamente esses públicos. Mantenho-me aberta a todas as pessoas que construam comigo um ambiente terapêutico de respeito e acolhimento.</p>
      <p className="m-5 justificar-texto">Uma dúvida que frequentemente surge é com relação a duração das sessões e frequência do processo terapêutico:
        Trabalho com encontros semanais que duram entre 45 - 50 minutos, sendo que o tempo do processo dependerá de cada caso e seus objetivos, que irão sendo identificados e avaliados entre as partes ao longo do acompanhamento, conforme o plano terapêutico singular construído para a pessoa acompanhada.</p>
      <p className="m-5 justificar-texto">Para mais informações, entre em contato. Adorarei te conhecer!</p>
    </div>
  );
}

export default Atendimento;
