import React from 'react';

function Header() {
  return (
    <div className="position-relative overflow-hidden p-1 p-md-5 m-md-3 text-center bg-light row align-items-center">
      <div className="col-md-5 p-lg-5 mx-auto my-3">
        <h1 className="display-4 font-weight-normal">Manô Medeiros</h1>
        <p className="font-weight-light">Psicóloga CRP 07/33416</p>
        <p className="font-weight-light justificar-texto m-5 mb-3">Há mais de quatro anos ofertando um espaço psicoterapêutico seguro e ético para pessoas LGBTI+ e mulheres.</p>
        <p className="font-weight-light justificar-texto m-5 mt-0 ">Psicoterapia individual e de relação (casal)</p>
        {/* <p className="lead font-weight-normal">Psicóloga</p>
        <a className="btn btn-outline-secondary" href="#">Em breve</a> */}

      </div>
      <div className="col-md-5 p-lg-5  mx-auto my-3">
        <img src="img-mano-3.jpeg" className="img-fluid rounded-circle w-75" alt="..."></img>
      </div>
    </div>
  );
}

export default Header;
